const platformConfig = {
  campaign_status_codes: {
    0: { name: "Reporting not started", color_class: "light", percentage: "0", text_color: "dark" },
    1: { name: "Product Info updated", color_class: "productinfo", percentage: "20", text_color: "light" },
    2: { name: "Data Processed", color_class: "dataprocesses", percentage: "40", text_color: "light" },
    3: { name: "Visualisation done", color_class: "visualdone", percentage: "60", text_color: "light" },
    4: { name: "Data Extracted", color_class: "dataextracted", percentage: "80", text_color: "light" },
    5: { name: "Report Generated", color_class: "reportdone", percentage: "100", text_color: "light" },
  },
  assignee_values: [
    { value: "Nithin", label: "Nithin" },
    { value: "Anik", label: "Anik" },
    { value: "Jaya", label: "Jaya" },
    { value: "Shivani", label: "Shivani" },
  ],
  checkout_type_values: [
    { value: "Trolley+All", label: "Shoppers with trolley & all shoppers" },
    { value: "only-trolley", label: "All shoppers with trolley" },
    // {value:"basket_trolley","label":"Basket_trolley"},
    { value: "cap-counter", label: "All shoppers with a purchase at a C.A.P. counter" },
    // {value:"contact_cr","label":"Contact_cr"},
    { value: "detected-basket", label: "All shoppers with basket and passed a screen" },
    { value: "detected-basket_trolley", label: "All shoppers with trolley or basket and passed a screen" },
    { value: "detected-trolley", label: "All shoppers with trolley and passed a screen" },
    { value: "instore", label: "All shoppers with a purchase at an instore counter" },
    { value: "only-basket", label: "All shoppers with basket" },
    { value: "Only_trolley_basket", label: "All shoppers with trolley or basket" },
    { value: "All", label: "All" },
  ],
  finalized_checkout_vals: ["Trolley+All", "only-trolley", "All"],
  activity_types: [
    { value: "activity_1", label: "Discount" },
    { value: "activity_2", label: "Second Placement" },
    { value: "activity_3", label: "Public Event" },
    { value: "activity_4", label: "Other" },
    { value: "add_manually", label: "Add Manually" },
  ],
  campaign_status_codes_new: {
    0: { name: "Reporting not started", color_class: "light", percentage: "0", text_color: "dark" },
    1: { name: "Product Info updated", color_class: "productinfo", percentage: "20", text_color: "light" },
    2: { name: "ETL Activated", color_class: "dataprocesses", percentage: "40", text_color: "light" },
    3: { name: "Data Processed", color_class: "dataextracted", percentage: "60", text_color: "light" },
    4: { name: "Visuals Generated", color_class: "visualdone", percentage: "80", text_color: "light" },
  },
  initial_load_count: 10,
  required_dates_for_reporting: 7,
  valid_file_types_for_pictures: ["image/jpeg", "image/png", "image/jpg", "video/mp4"],
  valid_file_types_for_clips: ["video/mp4"],
  valid_file_types_for_pictures_withExtention: [".jpeg", ".png", ".jpg", ".mp4"],
  valid_file_types_for_clips_withExtention: [".mp4"],
  valid_file_types: [
    "image/jpeg",
    "image/png",
    "image/gif",
    "image/bmp",
    "image/webp",
    "video/mp4",
    "video/webm",
    "video/ogg",
  ],
  timezone: "Europe/Berlin",
  hour_values: [
    "00:00",
    "00:05",
    "00:15",
    "00:30",
    "00:45",
    "01:00",
    "01:15",
    "01:30",
    "01:45",
    "02:00",
    "02:15",
    "02:30",
    "02:45",
    "03:00",
    "03:15",
    "03:30",
    "03:45",
    "04:00",
    "04:15",
    "04:30",
    "04:45",
    "05:00",
    "05:15",
    "05:30",
    "05:45",
    "06:00",
    "06:15",
    "06:30",
    "06:45",
    "07:00",
    "07:15",
    "07:30",
    "07:45",
    "08:00",
    "08:15",
    "08:30",
    "08:45",
    "09:00",
    "09:15",
    "09:30",
    "09:45",
    "10:00",
    "10:15",
    "10:30",
    "10:45",
    "11:00",
    "11:15",
    "11:30",
    "11:45",
    "12:00",
    "12:15",
    "12:30",
    "12:45",
    "13:00",
    "13:15",
    "13:30",
    "13:45",
    "14:00",
    "14:15",
    "14:30",
    "14:45",
    "15:00",
    "15:15",
    "15:30",
    "15:45",
    "16:00",
    "16:15",
    "16:30",
    "16:45",
    "17:00",
    "17:15",
    "17:30",
    "17:45",
    "18:00",
    "18:15",
    "18:30",
    "18:45",
    "19:00",
    "19:15",
    "19:30",
    "19:45",
    "20:00",
    "20:15",
    "20:30",
    "20:45",
    "21:00",
    "21:15",
    "21:30",
    "21:45",
    "22:00",
    "22:15",
    "22:30",
    "22:45",
    "23:00",
    "23:15",
    "23:30",
    "23:45",
    "23:50",
  ],
  availableToUserTypes: ["cyreen", "national", "branch"],
};

export default platformConfig;
