import * as React from "react";
import SelectBox from "../../../components/commonComponents/Selectors/SelectBox";
import { Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { checkCompanyId, fetchCompanies } from "../../../api_calls/userManagement";
import {
  checkRegionId,
  filterRegionsAndStores,
  loadActiveStores,
  loadRegions,
  loadStores,
  preSelectionOfStores,
} from "../../../api_calls/campaigns";
import { updateStateData } from "../../../Store/actions";
import MultiSelect from "../../../components/commonComponents/Selectors/MultiSelect";

export default function StoreHierarchy({ fieldNames, fieldError, selectionInfo, usedFor, availableStores, actionName }) {
  // console.log("store Hierarchy stores: ", availableStores)
  const currentStateData = useSelector((state) => state.userSelections);
  const dispatchAction = useDispatch();

  const handleFieldChange = (fieldData) => {
    dispatchAction(updateStateData(fieldData));
  };

  const onStoreSelection = (selectedOption) => {
    // console.log("selectedOption: ", selectedOption)
    const storeSelections = [...selectedOption];
    let selectedStores = [];
    if (selectedOption.length !== 0) {
      selectedOption.forEach((storeInfo) => {
        if (storeInfo.id === "all") {
          currentStateData.filtered_stores.forEach((filteredInfo) => {
            if (filteredInfo.id !== "all") {
              selectedStores = [...selectedStores, filteredInfo.label];
            }
          });
        } else {
          selectedStores = [...selectedStores, storeInfo.label];
        }
      });
    }
    const selectedData = {
      [fieldNames.stores]: selectedStores,
      selected_store_options: storeSelections,
    };

    handleFieldChange(selectedData);
  };

  // const test = async () => {
  //   onStoreSelection(currentStateData?.selected_store_options);
  // }

  React.useEffect(() => {
    // load companies list
    // fetchCompanies();
    // load regions
    loadRegions().then((regInfo) => {
      //load stores
      if (usedFor === "clips") {
        filterRegionsAndStores("clips").then((info) => {
          // need to set default options if selection info is undefined
          preSelectionOfStores(selectionInfo, onStoreSelection);
        });
      } else {
        loadActiveStores().then((storeInfo) => {
          filterRegionsAndStores("pictures").then((info) => {
            // need to set default options if selection info is undefined
            preSelectionOfStores(selectionInfo, onStoreSelection);
          });

          //  picture_store also needs to be prefilled if the user can not change the available to field.
          // onStoreSelection(currentStateData?.selected_store_options);
        });
      }
    });
  }, []);

  // React.useEffect(() => {
  //   if (currentStateData?.selected_store_options) {
  //     onStoreSelection(currentStateData.selected_store_options);
  //   }
  // }, [currentStateData?.selected_store_options]); // This will run when the value changes and exists

  return (
    <Stack direction={"row"} spacing={4}>
      {/* --------- companies ------------ */}
      {/* <MultiSelect
        label="Company"
        enableFullWidth={true}
        options={typeof currentStateData.company_name_ids !== "undefined" ? currentStateData.company_name_ids : []}
        defaultVal={
          typeof currentStateData.selected_company_options !== "undefined" ? currentStateData.selected_company_options : []
        }
        onChangeCall={(selectedOption) => {
          const companySelections = [...selectedOption];
          let selectedCompanies = [];
          let companiesIdArray = [];
          if (selectedOption.length !== 0) {
            selectedOption.forEach((companyInfo) => {
              companiesIdArray = [...companiesIdArray, companyInfo.id];
              selectedCompanies = [...selectedCompanies, companyInfo.label];
            });
          }
          const selectedData = {
            selected_company_ids: companiesIdArray,
            selected_company_names: selectedCompanies,
            selected_company_options: companySelections,
          };

          handleFieldChange(selectedData);
          filterRegionsAndStores();
        }}
      /> */}
      {/* ----------- regions --------- */}
      <MultiSelect
        enableFullWidth={true}
        label="Regions"
        options={typeof currentStateData.filtered_regions !== "undefined" ? currentStateData.filtered_regions : []}
        defaultVal={
          typeof currentStateData.selected_region_options !== "undefined" ? currentStateData.selected_region_options : []
        }
        selectionCheck={(option, value) => option.id === value.id}
        onChangeCall={(selectedOption) => {
          const regionSelections = [...selectedOption];
          let selectedRegions = [];
          let regionIdArray = [];
          if (selectedOption.length !== 0) {
            selectedOption.forEach((regionInfo) => {
              regionIdArray = [...regionIdArray, regionInfo.id];
              selectedRegions = [...selectedRegions, regionInfo.label];
            });
          }
          const selectedData = {
            selected_region_ids: regionIdArray,
            selected_region_names: selectedRegions,
            selected_region_options: regionSelections,
          };

          handleFieldChange(selectedData);
          filterRegionsAndStores(usedFor).then((info) => {
            // need to set default options if selection info is undefined
            preSelectionOfStores(selectionInfo, onStoreSelection);
          });
        }}
      />
      {/* ---------- stores ---------- */}
      {/* {typeof currentStateData.filtered_stores !== "undefined" && ( */}
      <MultiSelect
        label="Stores*"
        elemId={"storesDropDown"}
        enableFullWidth={true}
        options={typeof currentStateData.filtered_stores !== "undefined" ? currentStateData.filtered_stores : []}
        defaultVal={
          typeof currentStateData.selected_store_options !== "undefined" ? currentStateData.selected_store_options : []

          // started to automatically select the all option -> needs to be finished!
          // typeof currentStateData.selected_store_options !== "undefined"
          // ? currentStateData.selected_store_options
          // : currentStateData.available_to_selection.available_to_selection.length !== 0
          //   ? currentStateData.filtered_stores : []
        }
        selectionCheck={(option, value) => option.label === value.label}
        onChangeCall={(selectedOption) => {
          onStoreSelection(selectedOption);
          // const storeSelections = [...selectedOption];
          // let selectedStores = [];
          // if (selectedOption.length !== 0) {
          //   selectedOption.forEach((storeInfo) => {
          //     selectedStores = [...selectedStores, storeInfo.label];
          //   });
          // }
          // const selectedData = {
          //   [fieldNames.stores]: selectedStores,
          //   selected_store_options: storeSelections,
          // };

          // handleFieldChange(selectedData);
        }}
        fieldError={fieldError !== "" ? fieldError : ""}
      />
      {/* )} */}
    </Stack>
  );
}