import React, { useState } from "react";
import { Box, Paper, Stack, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  HtmlTooltip,
  IconContainer,
  StyledInformationSpan,
  StyledMainBox,
  StyledStack,
} from "../../../components/commonComponents/StyledComponents/styled";
import UploadButton from "../../../components/commonComponents/FileUpload/UploadButton";
import ActionButton from "../../../components/commonComponents/Buttons/ActionButton";
import { CustomTextInput } from "../../../components/commonComponents/TextInputs/style";
import { StyledTableCell } from "../../../components/commonComponents/TabularLists/styles";
import { Delete, Download } from "@mui/icons-material";

const UploadData = () => {
  const userStateData = useSelector((state) => state.userSelections);
  const [searchVal, setSearchVal] = useState("");
  return (
    <StyledMainBox component="main">
      <Typography variant="body2" sx={{ fontSize: "15px" }}>
        In this section you can upload receipt files which are required for our data processing of contact-based advertising
        campaigns. Once the receipt file was used for processing data, they are removed from this section. You may use the
        Receipt Monitoring to track the receipt uploads for earlier periods. Please make sure to use unique file names.
        <Box height={10} />
        <i>For receipt uploads, we only accept .csv files.</i>
      </Typography>
      <Box height={20} />
      {/* ------- file upload -------- */}
      <Stack direction={"row"} spacing={4}>
        <UploadButton uploadButtonId={"uploadDataFiles"} uploadLabel={"Upload File(s)"} acceptedFileType={"text/csv"} />
      </Stack>
      <Box height={10} />
      <h3>Existing File Uploads</h3>
      {/* ========== search box and receipt monitoring link ========== */}
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <Box>
          <CustomTextInput
            variant="standard"
            label="Search"
            type={"text"}
            value={searchVal}
            onChange={(e) => {
              setSearchVal(e.target.value);
            }}
            onBlur={(e) => {}}
          />
        </Box>
        <Box display="flex" alignItems="center">
          <Box ml={2}>
            {/* Add margin between the buttons */}
            <ActionButton
              onClick={(e) => {
                e.preventDefault();
              }}
              label="Receipt Monitoring"
              icon={<></>}
            />
          </Box>
        </Box>
      </Box>
      <Box height={10} />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          {/* ------ header row ------ */}
          <TableHead>
            <TableRow>
              <StyledTableCell align="left" className="text-wrapper">
                #
              </StyledTableCell>
              <StyledTableCell align="left" className="text-wrapper">
                File Name
              </StyledTableCell>
              <StyledTableCell align="left" className="text-wrapper">
                Upload Date
              </StyledTableCell>
              <StyledTableCell align="left" className="text-wrapper">
                File Size
              </StyledTableCell>
              <StyledTableCell sx={{ width: "50px" }} align="left" className="text-wrapper">
                Action
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <StyledTableCell component="td" scope="row" className="text-wrapper">
                <StyledStack direction={"row"}>1</StyledStack>
              </StyledTableCell>
              {/* --------- name ---------- */}
              <StyledTableCell component="td" scope="row" className="text-wrapper wrapLargeText">
                <StyledStack direction={"row"}>File 1</StyledStack>
              </StyledTableCell>
              {/* ----------- Upload date --------- */}
              <StyledTableCell component="td" scope="row" className="text-wrapper wrapLargeText">
                <StyledStack direction={"row"}>03/12/2024</StyledStack>
              </StyledTableCell>
              {/* ---------- File Size --------- */}
              <StyledTableCell component="td" scope="row" className="text-wrapper">
                <StyledStack direction={"row"} spacing={2}>
                  200 KB
                </StyledStack>
              </StyledTableCell>

              {/* -------- Action --------- */}
              <StyledTableCell component="td" scope="row" className="text-wrapper">
                <StyledStack direction={"row"} spacing={2}>
                  {/* <StyledSwitch checked={storeLoc.active} /> */}
                  <HtmlTooltip title={"download"}>
                    <IconContainer>
                      <Download sx={{ color: "#AF3241", marginTop: "5px" }} />
                    </IconContainer>
                  </HtmlTooltip>
                  <HtmlTooltip title={"delete"}>
                    <IconContainer>
                      <Delete sx={{ color: "#AF3241", marginTop: "5px" }} />
                    </IconContainer>
                  </HtmlTooltip>
                </StyledStack>
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell component="td" scope="row" className="text-wrapper">
                <StyledStack direction={"row"}>2</StyledStack>
              </StyledTableCell>
              {/* --------- name ---------- */}
              <StyledTableCell component="td" scope="row" className="text-wrapper wrapLargeText">
                <StyledStack direction={"row"}>File 2</StyledStack>
              </StyledTableCell>
              {/* ----------- Upload date --------- */}
              <StyledTableCell component="td" scope="row" className="text-wrapper wrapLargeText">
                <StyledStack direction={"row"}>05/12/2024</StyledStack>
              </StyledTableCell>
              {/* ---------- File Size --------- */}
              <StyledTableCell component="td" scope="row" className="text-wrapper">
                <StyledStack direction={"row"} spacing={2}>
                  200 KB
                </StyledStack>
              </StyledTableCell>

              {/* -------- Action --------- */}
              <StyledTableCell component="td" scope="row" className="text-wrapper">
                <StyledStack direction={"row"} spacing={2}>
                  {/* <StyledSwitch checked={storeLoc.active} /> */}
                  <HtmlTooltip title={"download"}>
                    <IconContainer>
                      <Download sx={{ color: "#AF3241", marginTop: "5px" }} />
                    </IconContainer>
                  </HtmlTooltip>
                  <HtmlTooltip title={"delete"}>
                    <IconContainer>
                      <Delete sx={{ color: "#AF3241", marginTop: "5px" }} />
                    </IconContainer>
                  </HtmlTooltip>
                </StyledStack>
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </StyledMainBox>
  );
};

export default UploadData;
