import React from "react";
import { Box, Stack, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { StyledMainBox } from "../../../components/commonComponents/StyledComponents/styled";

import { Close } from "@mui/icons-material";
import CloseButton from "../../../components/commonComponents/Buttons/CloseButton";

import { resetCampaignSheetData } from "../../../api_calls/campaignSheet";
import ProductInformation from "../listings/ProductInformation";
import { useNavigate } from "react-router-dom";
import { fetchCampaign } from "../../../api_calls/campaignRequests";

const ProductList = ({ open, onClose, size, usedFor, closeModal, campaignRecord }) => {
  const id = campaignRecord.campaign_id;
  const retailer = campaignRecord.retailer_company_name;
  const campaign_name = campaignRecord.campaign_name;
  const navigate = useNavigate();

  React.useEffect(() => {
    fetchCampaign(id, "products");
  }, []);
  return (
    <Dialog
      open={open}
      onClose={(e) => {
        e.preventDefault();
      }}
      fullWidth={true}
      maxWidth={size}
    >
      <DialogTitle> Analytics Products</DialogTitle>
      <DialogContent>
        <StyledMainBox component="main">
          <ProductInformation campaignId={id} campaign_name={campaign_name} retailer={retailer} />
        </StyledMainBox>
      </DialogContent>
      <Box height={20} />
      <DialogActions>
        <Stack direction={"row"} spacing={2}>
          <CloseButton
            onClick={(e) => {
              e.preventDefault();
              /*
                reset the campaign sheet data (process of creating an adlevel will start all over again 
                if user comes back from the upload campaign sheet page and revisit the page for a 
                different campaign or even for same campaign.
              */
              resetCampaignSheetData();
              closeModal();
            }}
            label="Cancel"
            icon={<Close />}
          />
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default ProductList;
