import { toast } from "react-toastify";
import { deleteItems, updateStateData } from "../Store/actions";
import store from "../Store/store";
import { fetch_adlevel_names, resetCampaignSheetData, restructure_product_information } from "./campaignSheet";
import { loadStores, updateCampaignStatus } from "./campaigns";
import { sendApiRequest } from "./requestConfig";
import { fetchMarketingActivities } from "./marketingActivities";
import { fetchBrandFilters } from "./brandReport";
import {
  checkStatus,
  dateInCorrectTimezone,
  dbFormatDate,
  formatToStandardDate,
  standardFormatToday,
  fetchWeekNumbers,
  dateObjForToday,
  getFirstDayTwoMonthsAgo,
  erase_duplicates,
} from "./utils";
import platformConfig from "../platformConfig";
import moment from "moment-timezone";
import { checkUserDetailsFromCookie } from "./userManagement";

const validateCampaignsInfo = () => {
  const currentStateData = store.getState().userSelections;
  let error = false;
  let errorMessages = {};

  // campaign name
  const campaignName =
    typeof currentStateData.new_campaign_data !== "undefined" &&
    typeof currentStateData.new_campaign_data.campaign_name !== "undefined"
      ? currentStateData.new_campaign_data.campaign_name
      : "";
  // brand name
  const brandName =
    typeof currentStateData.new_campaign_data !== "undefined" &&
    typeof currentStateData.new_campaign_data.brand_name !== "undefined"
      ? currentStateData.new_campaign_data.brand_name
      : "";
  // start date
  const startDate =
    typeof currentStateData.new_campaign_data !== "undefined" &&
    typeof currentStateData.new_campaign_data.campaign_start_date !== "undefined"
      ? currentStateData.new_campaign_data.campaign_start_date
      : "";
  // end date
  const endDate =
    typeof currentStateData.new_campaign_data !== "undefined" &&
    typeof currentStateData.new_campaign_data.campaign_end_date !== "undefined"
      ? currentStateData.new_campaign_data.campaign_end_date
      : "";
  // retailer company id
  // const retailerCompanyId =
  //   typeof currentStateData.new_campaign_data !== "undefined" &&
  //   typeof currentStateData.new_campaign_data.campaign_retailer_company_id !== "undefined"
  //     ? currentStateData.new_campaign_data.campaign_retailer_company_id
  //     : "";
  // brand company id
  const brandCompanyId =
    typeof currentStateData.new_campaign_data !== "undefined" &&
    typeof currentStateData.new_campaign_data.campaign_brand_company_id !== "undefined"
      ? currentStateData.new_campaign_data.campaign_brand_company_id
      : "";

  const manualBrandCompany =
    typeof currentStateData.new_campaign_data !== "undefined" &&
    typeof currentStateData.new_campaign_data.manual_brand_company !== "undefined"
      ? currentStateData.new_campaign_data.manual_brand_company
      : false;

  const manualBrandCompanyName =
    typeof currentStateData.new_campaign_data !== "undefined" &&
    typeof currentStateData.new_campaign_data.campaign_brand_company_name !== "undefined"
      ? currentStateData.new_campaign_data.campaign_brand_company_name
      : "";

  const reportStatus =
    typeof currentStateData.new_campaign_data !== "undefined" &&
    typeof currentStateData.new_campaign_data.report_status !== "undefined"
      ? currentStateData.new_campaign_data.report_status
      : "";

  const impressionStatus =
    typeof currentStateData.new_campaign_data !== "undefined" &&
    typeof currentStateData.new_campaign_data.impression_status !== "undefined"
      ? currentStateData.new_campaign_data.impression_status
      : "";

  const checkShareList =
    typeof currentStateData.new_campaign_data !== "undefined" &&
    typeof currentStateData.new_campaign_data.share_list_selection !== "undefined"
      ? currentStateData.new_campaign_data.share_list_selection
      : [];

  // check campaign
  if (campaignName === "") {
    error = true;
    errorMessages = {
      ...errorMessages,
      campaign_name: "Please enter the campaign name",
    };
  }
  // check brandName
  if (brandName === "") {
    error = true;
    errorMessages = {
      ...errorMessages,
      brand_name: "Please enter the brand name",
    };
  }
  // check start date
  if (startDate === "") {
    error = true;
    errorMessages = {
      ...errorMessages,
      start_date: "Please select the start date for the campaign",
    };
  }
  // check end date
  if (endDate === "") {
    error = true;
    errorMessages = {
      ...errorMessages,
      end_date: "Please select the end date for the campaign",
    };
  }

  // validate that start should not be after end date and vice versa
  if (startDate !== "" && endDate !== "") {
    const formattedStartDate = startDate; //dateInCorrectTimezone();
    const formattedEndDate = endDate; //dateInCorrectTimezone();
    //start/end should not be after end date
    if (formattedEndDate < formattedStartDate) {
      error = true;
      errorMessages = {
        ...errorMessages,
        end_date: "End date must be after the start date",
      };
    }
  }

  // retailer company
  // if (retailerCompanyId === "") {
  //   error = true;
  //   errorMessages = {
  //     ...errorMessages,
  //     retailer_company: "Please select the retailer company",
  //   };
  // }
  // brand company

  if (brandCompanyId === "" && manualBrandCompany === false) {
    error = true;
    errorMessages = {
      ...errorMessages,
      brand_company: "Please select the brand company",
    };
  }

  if (manualBrandCompanyName === "" && manualBrandCompany === true) {
    error = true;
    errorMessages = {
      ...errorMessages,
      campaign_brand_company_name: "Please enter the brand company name",
    };
  }

  if (manualBrandCompanyName !== "" && manualBrandCompany === true) {
    // check if the name already exist
    if (
      typeof currentStateData.brand_names_arr !== "undefined" &&
      currentStateData.brand_names_arr.includes(manualBrandCompanyName)
    ) {
      error = true;
      errorMessages = {
        ...errorMessages,
        campaign_brand_company_name: "Brand company name already exists",
      };
    }
  }

  // validate sharelist
  if (checkShareList.length === 0) {
    error = true;
    errorMessages = {
      ...errorMessages,
      share_list_selection: "Please make a selection",
    };
  }

  // show error message (if error === true)
  if (error === true) {
    toast("Please fill in valid information", { type: "error" });
    return {
      error: true,
      errorMessages,
    };
  } else {
    let campaignRequestObj = {
      brand: brandName,
      add_new_brand: manualBrandCompany,
      // retailer: parseInt(retailerCompanyId),
      date_end: dbFormatDate(new Date(endDate)),
      date_start: dbFormatDate(new Date(startDate)),
      name: campaignName,
      reporting: true,
      active: false,
      share_list:
        typeof currentStateData.new_campaign_data !== "undefined" &&
        typeof currentStateData.new_campaign_data.share_list !== "undefined"
          ? currentStateData.new_campaign_data.share_list
          : [],
    };

    if (manualBrandCompany === false) {
      campaignRequestObj = {
        ...campaignRequestObj,
        id_company_brand: parseInt(brandCompanyId),
      };
    } else {
      campaignRequestObj = {
        ...campaignRequestObj,
        new_brand_name: manualBrandCompanyName,
      };
    }

    // add campaign id (if found in redux)
    if (
      typeof currentStateData.new_campaign_data !== "undefined" &&
      typeof currentStateData.new_campaign_data.campaign_id !== "undefined" &&
      currentStateData.new_campaign_data.campaign_id !== ""
    ) {
      campaignRequestObj = {
        ...campaignRequestObj,
        id: currentStateData.new_campaign_data.campaign_id,
      };
      // add report status (if available)
      if (
        typeof currentStateData.new_campaign_data !== "undefined" &&
        typeof currentStateData.new_campaign_data.campaign_report_status_code !== "undefined" &&
        [2, 4].includes(currentStateData.new_campaign_data.campaign_report_status_code)
      ) {
        if (reportStatus === true) {
          campaignRequestObj = {
            ...campaignRequestObj,
            campaign_status: 4,
          };
        }
        if (reportStatus === false) {
          campaignRequestObj = {
            ...campaignRequestObj,
            campaign_status: 2,
          };
        }
      }

      if (impressionStatus === true) {
        campaignRequestObj = {
          ...campaignRequestObj,
          impression_status: 1,
        };
      }
    } else {
      campaignRequestObj = {
        ...campaignRequestObj,
        campaign_status: 0,
      };
    }

    // return validated request body for the clips add/update operation
    return {
      error: false,
      campaign_request: campaignRequestObj,
    };
  }
};

// to save/update campaigns
export const saveCampaignsInformation = async (usedfor) => {
  const validateInfo = validateCampaignsInfo();
  let campaignResponse = {};
  if (validateInfo.error === true) {
    campaignResponse = {
      ...campaignResponse,
      success: false,
      errorMessages: validateInfo.errorMessages,
    };
  } else {
    let reqEndPoint =
      typeof validateInfo.campaign_request.id !== "undefined" && validateInfo.campaign_request.id !== ""
        ? "campaigns/update"
        : "campaigns/add";
    let reqMethod =
      typeof validateInfo.campaign_request.id !== "undefined" && validateInfo.campaign_request.id !== "" ? "PUT" : "POST";
    // send api request
    const saveCampaignInfo = await sendApiRequest(reqEndPoint, validateInfo.campaign_request, reqMethod);

    if (typeof saveCampaignInfo.success !== "undefined" && saveCampaignInfo.success === true) {
      // check if any error returned from the api
      if (
        typeof saveCampaignInfo.response !== "undefined" &&
        typeof saveCampaignInfo.response.success !== "undefined" &&
        saveCampaignInfo.response.success === false
      ) {
        if (
          typeof saveCampaignInfo.response.message !== "undefined" &&
          saveCampaignInfo.response.message === "DUPLICATE RESOURCES"
        ) {
          campaignResponse = {
            ...campaignResponse,
            success: false,
            errorMessages: {
              campaign_name: saveCampaignInfo?.response?.error_detail,
            },
          };
        } else {
          campaignResponse = {
            ...campaignResponse,
            success: false,
            errorMessages: {
              campaign_brand_company_name: saveCampaignInfo?.response?.error_detail,
            },
          };
        }

        toast("Please fill in valid information", { type: "error" });
      } else {
        const message = usedfor === "update" ? "Campaign updated successfully" : "Campaign added successfully";
        campaignResponse = {
          ...campaignResponse,
          success: true,
          message: message,
        };
        loadCampaigns();
        toast(message, { type: "success" });
      }
    } else {
      campaignResponse = {
        ...campaignResponse,
        success: false,
        errorMessages: "Sorry, Something went wrong",
      };
    }
  }

  return campaignResponse;
};

//load campaign list
export const loadCampaigns = async () => {
  const currentStateData = store.getState().userSelections;
  //reset campaign list before loading the campaigns (if campaigns are already loaded.)
  resetCampaignSheetData();
  // Old
  const campaignList = await sendApiRequest("campaigns", {}, "GET");

  if (typeof campaignList.success !== "undefined" && campaignList.success === true) {
    //restructure the campaign list json to fetch only the required data from the response and then save it to redux state
    const getRestructuredData = restructureCampaignData(campaignList.response);

    store.dispatch(
      updateStateData({
        campaign_list: getRestructuredData,
      })
    );
    // check if filters are already applied, then we need to refresh the filtered list as well.
    if (
      typeof currentStateData.campaign_list_filter_applied !== "undefined" &&
      typeof currentStateData.campaign_list_filters !== "undefined" &&
      currentStateData.campaign_list_filter_applied === true
    ) {
      filterCampaigns();
    }
    return {
      success: true,
    };
  } else {
    return {
      success: false,
    };
  }
};

export const loadCampaignsForUsers = async () => {
  const currentStateData = store.getState().userSelections;
  //reset campaign list before loading the campaigns (if campaigns are already loaded.)
  resetCampaignSheetData();
  const campaignList = await sendApiRequest("campaigns/exclusive-list", {}, "GET");

  if (typeof campaignList.success !== "undefined" && campaignList.success === true) {
    //restructure the campaign list json to fetch only the required data from the response and then save it to redux state
    const getRestructuredData = restructureCampaignData(campaignList.response);

    store.dispatch(
      updateStateData({
        exclusive_campaign_list: getRestructuredData,
      })
    );
    // check if filters are already applied, then we need to refresh the filtered list as well.
    if (
      typeof currentStateData.campaign_list_filter_applied !== "undefined" &&
      typeof currentStateData.campaign_list_filters !== "undefined" &&
      currentStateData.campaign_list_filter_applied === true
    ) {
      filterCampaigns();
    }
    return {
      success: true,
    };
  } else {
    return {
      success: false,
    };
  }
};

//fetch single campaign
export const fetchCampaign = async (campaignid, requestFor = "all") => {
  const campaignDetail = await sendApiRequest("campaigns/" + campaignid, {}, "GET");
  if (typeof campaignDetail.success !== "undefined" && campaignDetail.success === true) {
    const record = campaignDetail.response;
    if (typeof record.error !== "undefined" && record.error === true) {
      toast("Campaign data not found", { type: "error" });
      setTimeout(() => {
        window.location.href = "/check-campaigns/list";
      }, 2000);
    } else {
      // save data in redux
      store.dispatch(
        updateStateData({
          campaign_detail: {
            campaign_id: record.id,
            campaign_name: record.name,
            campaign_start_date: record.date_start,
            campaign_end_date: record.date_end,
            brand: record.brand,
            retailer_company_id: record.company_retailer.id,
            retailer_company_name: record.company_retailer.name,
            brand_company_id: record.company_brand.id,
            brand_company_name: record.company_brand.name,
            retailer: record.company_brand.id === 40 ? "REWE" : "EDEKA",
            notes: record.notes,
            tableau_project_id: record.tableauId,
            campaign_status_code: record.campaign_status,
            impression_report_status: record.impression_status === null ? 0 : record.impression_status,
            workbook_path: record.workbookPath,
            campaign_active_status: checkStatus(record.date_start, record.date_end),
            assignedUser: record.assignedUser,
            datasourceVersion: record.datasourceVersion,
            retailer_id: record.company_retailer.id, //record.company_brand.id === 40 ? 40 : 2,
            workbook_id: record.workbookId,
            campaign_stores: record?.store_names,
            root_company_id: record?.root_company_id,
            campaign_analytics_config:
              record?.campaign_analytics_config !== null
                ? record?.campaign_analytics_config
                    .sort((a, b) => {
                      return a.tab_position - b.tab_position;
                    })
                    .filter((tabConfig) => {
                      return tabConfig.tab_visible;
                    })
                : [],
            // [
            //   {
            //     tab_name: "TotalPerformance",
            //     tab_text: "Total Performance",
            //     tab_position: 1,
            //     tab_visible: true,
            //   },
            //   {
            //     tab_name: "SinglePerformance",
            //     tab_text: "Performance",
            //     tab_position: 2,
            //     tab_visible: true,
            //   },
            //   {
            //     tab_name: "Targeting",
            //     tab_text: "Optimization",
            //     tab_position: 3,
            //     tab_visible: true,
            //   },
            //   {
            //     tab_name: "MarketingActivities",
            //     tab_text: "Marketing Activities",
            //     tab_position: 1,
            //     tab_visible: true,
            //   },
            //   {
            //     tab_name: "ABTesting",
            //     tab_text: "A/B Testing",
            //     tab_position: 1,
            //     tab_visible: false,
            //   },
            // ],
          },
        })
      );

      //load adlevels for this campaign
      await checkProductInformation(campaignid, true);

      // // load marketing activity information
      await fetchMarketingActivities(campaignid);

      // // load campaign weeks
      await fetchWeekNumbers(record.date_start, record.date_end);

      // // load stores
      await loadStores();

      // // fetch brand report filters
      await fetchBrandFilters(campaignid);

      return {
        success: true,
      };
    }

    if (requestFor === "all") {
      //load adlevels for this campaign
      await checkProductInformation(campaignid, true);

      // // load marketing activity information
      await fetchMarketingActivities(campaignid);

      // // load campaign weeks
      await fetchWeekNumbers(record.date_start, record.date_end);

      // // load stores
      await loadStores();

      // // fetch brand report filters
      await fetchBrandFilters(campaignid);
    } else {
      if (requestFor === "products") {
        //load adlevels for this campaign
        await checkProductInformation(campaignid, true);
      }
      if (requestFor === "marketing_activity") {
        // // load marketing activity information
        await fetchMarketingActivities(campaignid);

        // // load campaign weeks
        await fetchWeekNumbers(record.date_start, record.date_end);

        // // load stores
        await loadStores();
      }
    }

    return {
      success: true,
    };
  } else {
    window.location.href = "/login";
  }
};

//sort campaigns by start date
const sortByStartDate = (campaignArray) => {
  return campaignArray.sort((next, prev) => {
    return formatToStandardDate(prev.campaign_start_date) - formatToStandardDate(next.campaign_start_date);
  });
};

export const compareDates = (startDate, compare = "start") => {
  const dateStrForToday = dateObjForToday(new Date());
  const today = moment.tz(dateStrForToday, platformConfig.timezone).startOf("day");
  const start = moment.tz(startDate, platformConfig.timezone).startOf("day");

  // Calculate the difference in days
  const daysDifference = today.diff(start, "days");

  let response = { daysDifference: 0 };

  if (today.isSame(start, "day")) {
    response = {
      ...response,
      status: compare === "start" ? "started_today" : "ended_today",
    };
  }

  if (today.isAfter(start, "day")) {
    response = {
      ...response,
      status: compare === "start" ? "started_days_ago" : "ended_days_ago",
      daysDifference: daysDifference,
    };
  }

  return response;
};

// this function will check campaign status based on start/end date and add only the required information to the final array which will be returned.
const restructureCampaignData = (campaignListDetails) => {
  let restructuredActiveData = [];
  let restructuredScheduledData = [];
  let restructuredCompletedData = [];
  let restructuredAllCampaigns = [];
  let campaignNameArray = [];
  let campaignNameList = [];
  let activeCampaignNameList = [];
  let campaignCompanyList = [];
  let report_available_campaigns = [];

  let campaignDatesList = {};

  campaignListDetails.forEach((campaign) => {
    //we will only show those campaigns for which reporting has to be done.
    if (campaign.reporting === true) {
      //decide campaign status by comparing current date with start and end date of campaign.
      const currentDate = standardFormatToday();
      const campaignStartDate = formatToStandardDate(campaign.date_start);
      const campaignEndDate = formatToStandardDate(campaign.date_end);
      const checkCampaignStartStatus = compareDates(campaign.date_start);
      const checkCampaignEndStatus = compareDates(campaign.date_end, "enddate");
      const campaignProgressStatus = checkStatus(campaign.date_start, campaign.date_end);
      let campaignDataObj = {
        campaign_id: campaign.id,
        campaign_name: campaign.name,
        campaign_start_date: campaign.date_start,
        campaign_end_date: campaign.date_end,
        brand: campaign.brand,
        retailer_company_id: campaign.company_retailer.id,
        retailer_company_name: campaign.company_retailer.name,
        brand_company_id: campaign.company_brand.id,
        brand_company_name: campaign.company_brand.name,
        retailer: campaign.company_brand.id === 40 ? "REWE" : "EDEKA",
        // retailer: campaign.company_brand.name,
        notes: campaign.notes,
        tableau_project_id: campaign.tableauId,
        campaign_status_code: campaign.campaign_status,
        impression_report_status: campaign.impression_status === null ? 0 : campaign.impression_status,
        campaign_start_status: checkCampaignStartStatus.status,
        campaign_end_status: checkCampaignEndStatus.status,
        days_passed: checkCampaignStartStatus.daysDifference,
        workbook_path: campaign.workbookPath,
        campaign_active_status: campaign.active,
        assignedUser: campaign.assignedUser,
        datasourceVersion: campaign.datasourceVersion,
        workbook_id: campaign.workbookId,
        last_edit: campaign.last_edit,
        share_list: typeof campaign.share_list !== "undefined" && campaign.share_list !== null ? campaign.share_list : [],
        optimization: {
          days: false,
          hours: true,
          stores: true,
        },
        location: {
          regions: 1,
          stores: 10,
          screen: 25,
          region_names: ["Saxony"],
        },
      };
      //create a retailer array for filters
      // if (!reatilersArray.includes(campaign.company_brand.name)) {
      //   reatilersArray.push(campaign.company_brand.name);
      // }

      // status = Scheduled
      if (campaignProgressStatus === "Scheduled") {
        campaignDataObj = {
          ...campaignDataObj,
          campaign_status: campaignProgressStatus,
        };
        restructuredScheduledData.push(campaignDataObj);
      }
      // status = Completed
      if (campaignProgressStatus === "Completed") {
        campaignDataObj = {
          ...campaignDataObj,
          campaign_status: "Completed",
        };
        restructuredCompletedData.push(campaignDataObj);
      }
      //status = Active
      if (campaignProgressStatus === "Active") {
        campaignDataObj = {
          ...campaignDataObj,
          campaign_status: "Active",
        };
        restructuredActiveData.push(campaignDataObj);
      }

      //create a campaign name array for filters
      if (!campaignNameArray.includes(campaign.name)) {
        campaignNameArray.push(campaign.name);
      }
      //create a campaign name array for filters
      if (!Object.values(campaignNameList).includes(campaign.name)) {
        campaignNameList.push({ id: campaign.id, label: campaign.name });
        campaignDatesList = {
          ...campaignDatesList,
          [campaign.id]: {
            campaign_start_date: campaign.date_start,
            campaign_end_date: campaign.date_end,
          },
        };
        //
        if (["Active", "Scheduled"].includes(campaignDataObj.campaign_status)) {
          activeCampaignNameList.push({ id: campaign.id, label: campaign.name });
        }
        if (campaign.campaign_status === 4) {
          const checkUserDetail = checkUserDetailsFromCookie();
          // need to filter campaigns that belong to logged user if it's not cyreen user
          if (typeof checkUserDetail.user_type !== "undefined" && checkUserDetail.user_type !== "cyreen") {
            if (campaign.company_retailer.name === checkUserDetail.assigned_company.name) {
              report_available_campaigns.push({ id: campaign.id, label: campaign.name });
            }
          } else {
            report_available_campaigns.push({ id: campaign.id, label: campaign.name });
          }
        }
      }
      // campaign company name list
      campaignCompanyList.push({ id: campaign.company_retailer.id, label: campaign.company_retailer.name });
      // all campaigns (irrespective of status)
      restructuredAllCampaigns.push(campaignDataObj);
    }
  });

  return {
    all_campaigns: sortByStartDate(restructuredAllCampaigns),
    // live_campaigns_list: sortByStartDate(restructuredActiveData),
    // scheduled_campaigns_list: sortByStartDate(restructuredScheduledData),
    // completed_campaigns_list: sortByStartDate(restructuredCompletedData),
    retailer_keywords: ["EDEKA", "REWE"],
    campaign_name_list: campaignNameArray,
    campaign_name_obj: campaignNameList,
    campaign_dates_list: campaignDatesList,
    active_campaign_name_list: activeCampaignNameList,
    campaign_company_list: campaignCompanyList,
    report_available_campaigns: report_available_campaigns,
  };
};

//update campaign data i.e., notes, active, status, workbook path, and tableau project id
export const updateCampaignData = async (requestBody, campaign_id) => {
  //save project id and update campaign status to be 2 i.e., data processing
  const updateData = await sendApiRequest("campaigns/data/update/" + campaign_id, requestBody, "PUT");

  if (typeof updateData.success !== "undefined" && updateData.success === true) {
    /*
      fetch campaign details once the data is updated 
      (it will automatically update the redux state with latest data)
    */
    fetchCampaign(campaign_id, "all");
    return {
      success: true,
      message: "Data updated successfully.",
    };
  } else {
    return {
      success: false,
      message: "Sorry, something went wrong.",
    };
  }
};

//save adlevels to the backend database
export const saveCampaignAdlevels = async (campaign_id) => {
  const currentStateData = store.getState().userSelections;
  //cross check if adlevels are added in redux store or not
  if (typeof currentStateData.created_adlevels !== "undefined" && currentStateData.created_adlevels.length !== 0) {
    const saveAdlevelResponse = await sendApiRequest("products/data/add", currentStateData.created_adlevels, "POST");

    if (saveAdlevelResponse.success === true) {
      // check if any error returned from the api
      if (
        typeof saveAdlevelResponse.response !== "undefined" &&
        typeof saveAdlevelResponse.response.success !== "undefined" &&
        saveAdlevelResponse.response.success === false
      ) {
        //no need to perform any action (it's unsure whether success param will be there in the actual success response, that's why temporarily we need to leave this block empty)
      } else {
        //update campaign status to be 1 i.e., product information saved
        // updateCampaignData({ status: 1 }, campaign_id);
        updateCampaignStatus(3, campaign_id);
        //reset adlevels in redux
        store.dispatch(
          updateStateData({
            created_adlevels: [],
            adlevels_saved: true,
          })
        );
      }
      return {
        success: true,
        api_response: saveAdlevelResponse,
      };
    }
  } else {
    return {
      success: false,
    };
  }
};

// to add a new adlevel manually
export const addNewProductAdlevels = async (campaign_id, adlevelRequestParam) => {
  const saveAdlevelResponse = await sendApiRequest("products/data/add", adlevelRequestParam, "POST");

  if (saveAdlevelResponse.success === true) {
    // update campaign status
    updateCampaignStatus(3, campaign_id);
    //send a request at backend to fetch latest data.
    checkProductInformation(campaign_id);
    return {
      success: true,
      api_response: saveAdlevelResponse,
    };
  } else {
    return {
      success: false,
    };
  }
};

//update adlevels (Product information)
export const updateCampaignAdlevels = async (adlevelRecords) => {
  const currentStateData = store.getState().userSelections;
  //cross check if adlevels are added in redux store or not
  if (typeof adlevelRecords !== "undefined" && adlevelRecords.length !== 0) {
    const saveAdlevelResponse = await sendApiRequest("products/data/update", adlevelRecords, "PUT");

    if (saveAdlevelResponse.success === true) {
      //reset adlevels in redux and show a link to check saved adlevels
      store.dispatch(
        updateStateData({
          adlevels_updated: true,
        })
      );
      return {
        success: true,
      };
    }
  } else {
    return {
      success: false,
    };
  }
};

//delete single adlevel based on id
export const deleteSingleAdlevel = async (adlevelId) => {
  const currentStateData = store.getState().userSelections;
  //cross check if adlevels are added in redux store or not
  if (typeof currentStateData.created_adlevels !== "undefined" && currentStateData.created_adlevels.length !== 0) {
    const deleteAdlevelResponse = await sendApiRequest("products/data/delete/" + adlevelId, "", "DELETE");

    if (deleteAdlevelResponse.success === true) {
      return {
        success: true,
        message: "adlevel deleted",
      };
    } else {
      return {
        success: false,
        message: "Sorry, something went wrong.",
      };
    }
  }
};

// delete multiple adlevels
export const deleteMultipleAdlevels = async (selectedAdlevels) => {
  let toBeDeletedRecords = [];
  const currentStateData = store.getState().userSelections;
  if (typeof currentStateData.created_adlevels !== "undefined" && currentStateData.created_adlevels.length !== 0) {
    const AdlevelsRecord = currentStateData.created_adlevels;
    /*
      __selectedAdlevels__ variable has indexes of the selected adlevels. 
      we need to loop through it to fetch the actual adlevel id that will be sent 
      in the request parameters.
      backend api will only be able to delete the records only if correct ids are sent 
    */
    selectedAdlevels.forEach((record) => {
      toBeDeletedRecords.push({
        id: AdlevelsRecord[record].id,
      });
    });
    //call backend api to delete multiple adlevels.
    const deleteAdlevelsResponse = await sendApiRequest("products/data/delete/", toBeDeletedRecords, "DELETE");
    //return response.
    if (deleteAdlevelsResponse.success === true) {
      return {
        success: true,
        message: "adlevel deleted",
      };
    } else {
      return {
        success: false,
        message: "Sorry, something went wrong.",
      };
    }
  }
};

//fetch adlevel details based on campaign id
export const checkProductInformation = async (campaign_id, adlevel = false) => {
  const checkInfoResponse = await sendApiRequest("products/campaign/" + campaign_id, "", "GET");
  if (checkInfoResponse.success === true) {
    /*
    need to restructure the product information response to match the created_adlevel 
    format which is formed at the time when ad levels are created from upload campaign 
    sheet page.
    */
    const restructuredData = restructure_product_information(checkInfoResponse.response, campaign_id);
    if (adlevel === true) {
      fetch_adlevel_names(restructuredData.restructuredData);
    }
  }
};

//fetch stores list from backend
export const fetchStores = async (retailer_id) => {
  const checkStoresResponse = await sendApiRequest("stores", "", "GET");

  if (checkStoresResponse.success === true) {
    /*need to filter stores based on the reatailer id.*/
    filter_stores(retailer_id, checkStoresResponse.response);
  }
};

//to filter stores based on retailer id
const filter_stores = (retailerId, storesListResponse) => {
  let filteredData = [];
  let storeIdList = {};
  //traverse all the records from campaignData
  storesListResponse.forEach((store) => {
    if (store.retailer_id === retailerId) {
      filteredData.push({ value: store.id, label: store.name });
      storeIdList = {
        ...storeIdList,
        [store.id]: {
          name_store: store.name,
          id_region: store.region.id,
          market_type: store.id_market_type,
        },
      };
    }
  });

  //save to redux
  store.dispatch(
    updateStateData({
      stores_list: filteredData,
      stores_detail: storeIdList,
    })
  );

  return {
    filteredData,
  };
};

//to match campaign list records with the filters applied.
const matchRecords = (item) => {
  const currentStateData = store.getState().userSelections;
  const appliedFilters = currentStateData.campaign_list_filters;
  const campaignStartDate = dateInCorrectTimezone(item.campaign_start_date);
  const campaignEndDate = dateInCorrectTimezone(item.campaign_end_date);
  let itemMatch = false;
  //retailer filter
  if (appliedFilters.retailer_filter.length !== 0) {
    if (appliedFilters.retailer_filter.find((retailer) => retailer.label === item.retailer_company_name)) {
      itemMatch = true;
    }
  }
  //assigned to
  if (appliedFilters.assigned_to_filter.length !== 0) {
    if (appliedFilters.assigned_to_filter.includes(item.assignedUser)) {
      itemMatch = true;
    }
  }
  //status filter
  if (appliedFilters.status_filter.length !== 0) {
    if (appliedFilters.status_filter.includes(item.campaign_status_code.toString())) {
      itemMatch = true;
    }
  }
  //campaign name filter
  if (appliedFilters.campaign_name !== "") {
    if (appliedFilters.campaign_name.includes(item.campaign_id)) {
      itemMatch = true;
    }
  }

  //when only start date is selected
  if (appliedFilters.startdate_filter !== "" && appliedFilters.enddate_filter === "") {
    const filterStartDate = dateInCorrectTimezone(appliedFilters.startdate_filter);
    if (campaignStartDate >= filterStartDate) {
      itemMatch = true;
    }
  }
  //when only end date is selected
  if (appliedFilters.enddate_filter !== "" && appliedFilters.startdate_filter === "") {
    const filterEndDate = dateInCorrectTimezone(appliedFilters.enddate_filter);
    if (campaignEndDate <= filterEndDate) {
      itemMatch = true;
    }
  }
  //when both start and end dates are selected
  if (appliedFilters.enddate_filter !== "" && appliedFilters.startdate_filter !== "") {
    const filterStartDate = dateInCorrectTimezone(appliedFilters.startdate_filter);
    const filterEndDate = dateInCorrectTimezone(appliedFilters.enddate_filter);
    if (campaignStartDate >= filterStartDate && campaignEndDate <= filterEndDate) {
      itemMatch = true;
    }
  }
  // when duration/progress filter is applied
  if (appliedFilters.duration_filter !== "" && appliedFilters.duration_filter !== "") {
    if (appliedFilters.duration_filter.includes(item.campaign_status)) {
      itemMatch = true;
    }
  }

  return itemMatch;
};

//filter campaigns according to the filters applied
export const filterCampaigns = () => {
  const currentStateData = store.getState().userSelections;
  let filteredLive = [];
  let filteredCompleted = [];
  let filteredScheduled = [];
  const appliedFilters = currentStateData.campaign_list_filters;

  //filter live campaigns
  if (
    typeof currentStateData.campaign_list !== "undefined" &&
    typeof currentStateData.campaign_list.all_campaigns !== "undefined" &&
    currentStateData.campaign_list.all_campaigns.length !== 0
  ) {
    currentStateData.campaign_list.all_campaigns.forEach((item) => {
      const checkMatch = matchRecords(item);
      if (checkMatch === true) {
        filteredLive.push(item);
      }
    });
  }

  //filter completed campaigns
  // if (
  //   typeof currentStateData.campaign_list !== "undefined" &&
  //   typeof currentStateData.campaign_list.completed_campaigns_list !== "undefined" &&
  //   currentStateData.campaign_list.completed_campaigns_list.length !== 0
  // ) {
  //   currentStateData.campaign_list.completed_campaigns_list.forEach((item) => {
  //     const checkMatch = matchRecords(item);
  //     if (checkMatch === true) {
  //       filteredCompleted.push(item);
  //     }
  //   });
  // }

  //filter scheduled campaigns
  // if (
  //   typeof currentStateData.campaign_list !== "undefined" &&
  //   typeof currentStateData.campaign_list.scheduled_campaigns_list !== "undefined" &&
  //   currentStateData.campaign_list.scheduled_campaigns_list.length !== 0
  // ) {
  //   currentStateData.campaign_list.scheduled_campaigns_list.forEach((item) => {
  //     const checkMatch = matchRecords(item);
  //     if (checkMatch === true) {
  //       filteredScheduled.push(item);
  //     }
  //   });
  // }

  store.dispatch(
    updateStateData({
      filtered_campaign_list: {
        all_campaigns: filteredLive,
        // live_campaigns_list: filteredLive,
        // scheduled_campaigns_list: filteredScheduled,
        // completed_campaigns_list: filteredCompleted,
      },
      campaign_list_filter_applied: true,
    })
  );

  return true;
};

// execute ETL scripts
export const executeEtl = async () => {
  const currentStateData = store.getState().userSelections;
  //subtract 2 minutes from current date and time, (Airflow requirement)
  const logical_date = new Date().setMinutes(new Date().getMinutes() - 2);
  const etlRequestBody = {
    conf: {
      arg1: currentStateData.campaign_detail.retailer_id,
      arg2: currentStateData.campaign_detail.campaign_id,
      arg3: "3",
    },
    dag_run_id: "etl_script_for_" + currentStateData.campaign_detail.campaign_id + "_" + Math.floor(Math.random() * 1000),
    logical_date: new Date(logical_date),
    note: "",
  };
  const checkEtlResponse = await sendApiRequest("etl/call", etlRequestBody, "POST");

  if (checkEtlResponse.success === true) {
    toast("ETL Request has been queued. It may take some time to complete", { type: "success" });
  } else {
    toast("Sorry, something went wrong.", { type: "error" });
  }
  return checkEtlResponse;
};

export const clearfilters = () => {
  //reset campaign listing
  store.dispatch(
    deleteItems([
      "campaign_list_filters",
      "filtered_campaign_list",
      "campaign_list_filter_applied",
      "clip_list_filters",
      "filtered_clips_list",
      "clips_list_filter_applied",
      "pic_list_filters",
      "filtered_pictures_list",
      "pics_list_filter_applied",
    ])
  );
};

// save stores detail to backend db
export const addStore = async (requestBody) => {
  const checkStoreResponse = await sendApiRequest("stores/add", requestBody, "POST");
  //return response.
  if (checkStoreResponse.success === true) {
    return {
      success: true,
      message: "store added",
    };
  } else {
    return {
      success: false,
      message: "Sorry, something went wrong.",
    };
  }
};

// download campaign data
export const downloadCampaignData = async (campaignName, dataOption) => {
  const downloadUrl = await sendApiRequest(
    "api/bucket/download-excel",
    {
      campaign_name: campaignName,
      export_type: dataOption,
    },
    "POST"
  );
  if (typeof downloadUrl.success !== "undefined" && downloadUrl.success === true) {
    if (typeof downloadUrl.response.error_detail !== "undefined" && downloadUrl.response.error_detail !== "") {
      toast(downloadUrl.response.error_detail, { type: "error" });
    } else {
      // download impressions
      if (typeof downloadUrl.response.impressions !== "undefined" && downloadUrl.response.impressions !== null) {
        window.location = downloadUrl.response.impressions;
      }
      // download conversion data - total
      if (
        typeof downloadUrl.response.performance_aggregate !== "undefined" &&
        downloadUrl.response.performance_aggregate !== null
      ) {
        if (downloadUrl.response.impressions === null && downloadUrl.response.performance_store === null) {
          window.location = downloadUrl.response.performance_aggregate;
        } else {
          setTimeout(() => {
            window.location = downloadUrl.response.performance_aggregate;
          }, 1000);
        }
      }

      // download conversion data - stores
      if (
        typeof downloadUrl.response.performance_store !== "undefined" &&
        downloadUrl.response.performance_store !== null
      ) {
        if (downloadUrl.response.impressions === null && downloadUrl.response.performance_aggregate === null) {
          window.location = downloadUrl.response.performance_store;
        } else {
          setTimeout(() => {
            window.location = downloadUrl.response.performance_store;
          }, 2000);
        }
      }
    }
  } else {
    return {
      success: false,
      message: "Sorry, something went wrong",
    };
  }
};

// return campaign names that aren't older than 2 months.
export const campaign_names_in_range = () => {
  var campaign_name_obj = [{}];
  const currentStateData = store.getState().userSelections;
  const firstDayTwoMonthsAgo = formatToStandardDate(getFirstDayTwoMonthsAgo());
  firstDayTwoMonthsAgo.setDate(firstDayTwoMonthsAgo.getDate() + 1);
  const today = standardFormatToday();
  const yesterday = formatToStandardDate(today);
  yesterday.setDate(today.getDate() - 1);

  currentStateData.campaign_list.campaign_name_obj.forEach((campaign_name) => {
    currentStateData.campaign_list.all_campaigns.forEach((element) => {
      if (element.campaign_name === campaign_name.label) {
        if (formatToStandardDate(element.campaign_end_date) > firstDayTwoMonthsAgo) {
          campaign_name_obj.push(campaign_name);
        }
      }
    });
  });
  return erase_duplicates(campaign_name_obj);
};

export const clip_names_in_range = () => {
  var clip_name_obj = [{}];
  const currentStateData = store.getState().userSelections;
  const firstDayTwoMonthsAgo = formatToStandardDate(getFirstDayTwoMonthsAgo());
  firstDayTwoMonthsAgo.setDate(firstDayTwoMonthsAgo.getDate() + 1);
  const today = standardFormatToday();
  const yesterday = formatToStandardDate(today);
  yesterday.setDate(today.getDate() - 1);

  currentStateData.clip_names_list.forEach((clip_name) => {
    currentStateData.clips_list.forEach((element) => {
      if (element.name === clip_name.label) {
        if (formatToStandardDate(element.end_date) > firstDayTwoMonthsAgo) {
          clip_name_obj.push(clip_name);
        }
      }
    });
  });
  return erase_duplicates(clip_name_obj);
};
