import React from "react";
import { Box, Paper, Stack, Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material";
import TabularList from "../../components/commonComponents/TabularLists/TabularList";
import ActionButton from "../../components/commonComponents/Buttons/ActionButton";
import {
  Add,
  Input,
  Output,
  Edit,
  OndemandVideo,
  Router,
  Save,
  Settings,
  ShoppingCartCheckout,
  ArrowDropDown,
  ArrowDropUp,
  CheckCircle,
  Cancel,
} from "@mui/icons-material";
import {
  HtmlTooltip,
  HtmlTooltipDisabled,
  IconContainer,
  IconText,
  IconTextDisabled,
  StyledDarkRedInformationTypography,
  StyledMainBox,
  StyledStack,
  StyledSwitch,
} from "../../components/commonComponents/StyledComponents/styled";
import SimpleModal from "../../components/commonComponents/Modals/SimpleModal";
import NewLocation from "./ModalContent/NewLocation";
import { useNavigate } from "react-router-dom";
import AccessControl from "../../components/commonComponents/Hoc/AccessControl";
import { StyledTableCell } from "../../components/commonComponents/TabularLists/styles";
import {
  fetchCountriesList,
  fetchOpeningHours,
  fetchStoreLocations,
  reset_infrastructure_config_page_data,
  sortStoreLocations,
} from "../../api_calls/infrastructure";
import { useDispatch, useSelector } from "react-redux";
import LoadMoreButton from "../../components/commonComponents/LoadMore/LoadMoreButton";
import { loadMoreData } from "../../api_calls/utils";
import { loadRegions } from "../../api_calls/campaigns";
import { deleteItems } from "../../Store/actions";
import { checkUserDetailsFromCookie } from "../../api_calls/userManagement";

const Locations = () => {
  const infraDetails = useSelector((state) => state.userSelections);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  let availableToUserTypes = ["cyreen", "branch"];
  const loggedUserDetails = checkUserDetailsFromCookie();
  const [sortingParam, setSortingParam] = React.useState({
    cyreenId: true,
    store_name: false,
  });
  const [sortingMeasure, setSortingMeasure] = React.useState({
    cyreenId: "asc",
    store_name: "asc",
  });
  const navigate = useNavigate();
  const dispatchAction = useDispatch();

  let totalInfraStoreItems =
    typeof infraDetails.infra_store_locs !== "undefined" && infraDetails.infra_store_locs.length !== 0
      ? infraDetails.infra_store_locs.length
      : 0;
  let storeLocListitems =
    typeof infraDetails.infra_store_locs !== "undefined" && infraDetails.infra_store_locs.length !== 0
      ? infraDetails.infra_store_locs.slice(0, infraDetails.initial_load_count_for_infra_store)
      : [];

  const closeModal = () => {
    dispatchAction(deleteItems(["new_store_loc_data"]));
    setIsModalOpen(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const sortByCyreenId = () => {
    setSortingParam((prevState) => {
      return {
        ...prevState,
        cyreenId: true,
        store_name: false,
      };
    });
    setSortingMeasure((prevState) => {
      return {
        ...prevState,
        cyreenId: prevState.cyreenId === "asc" ? "desc" : "asc",
      };
    });
    //sort store locations
    sortStoreLocations(
      { cyreenId: true, store_name: false },
      {
        ...sortingMeasure,
        cyreenId: sortingMeasure.cyreenId === "asc" ? "desc" : "asc",
      }
    );
  };

  const sortByStoreName = () => {
    setSortingParam((prevState) => {
      return {
        ...prevState,
        cyreenId: false,
        store_name: true,
      };
    });
    setSortingMeasure((prevState) => {
      return {
        ...prevState,
        store_name: prevState.store_name === "asc" ? "desc" : "asc",
      };
    });
    //sort store locations
    sortStoreLocations(
      { cyreenId: false, store_name: true },
      {
        ...sortingMeasure,
        store_name: sortingMeasure.store_name === "asc" ? "desc" : "asc",
      }
    );
  };

  //initially only 10 results will be loaded, further 10 will be loaded on click of load more button
  const loadMore = () => {
    loadMoreData("initial_load_count_for_infra_store");
  };

  React.useEffect(() => {
    fetchStoreLocations();
    fetchOpeningHours();
    loadRegions(true);
    fetchCountriesList();

    reset_infrastructure_config_page_data();
  }, []);

  return (
    <>
      <StyledMainBox component="main">
        <Box height={30} />
        <h1>Stores</h1>
        {/* ============ chose data fields button =========== */}
        <Stack direction="row" spacing={2}>
          <ActionButton
            onClick={(e) => {
              e.preventDefault();
              openModal();
            }}
            label={"Add Store"}
            icon={<Add />}
          />
        </Stack>
        {/* ============== campaign listing ============ */}
        <Box height={30} />
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            {/* ------ header row ------ */}
            <TableHead>
              <TableRow>
                <StyledTableCell align="left" className="text-wrapper">
                  Cyreen ID
                  {sortingMeasure.cyreenId === "asc" ? (
                    <ArrowDropDown sx={{ cursor: "pointer" }} onClick={sortByCyreenId} />
                  ) : (
                    <ArrowDropUp sx={{ cursor: "pointer" }} onClick={sortByCyreenId} />
                  )}
                </StyledTableCell>
                <StyledTableCell align="left" className="text-wrapper">
                  Name
                  {sortingMeasure.store_name === "asc" ? (
                    <ArrowDropDown sx={{ cursor: "pointer" }} onClick={sortByStoreName} />
                  ) : (
                    <ArrowDropUp sx={{ cursor: "pointer" }} onClick={sortByStoreName} />
                  )}
                </StyledTableCell>
                <StyledTableCell align="left" className="text-wrapper">
                  Owner
                </StyledTableCell>
                <StyledTableCell align="left" className="text-wrapper">
                  Active Devices
                </StyledTableCell>

                <StyledTableCell align="left" className="text-wrapper">
                  Status
                </StyledTableCell>
                <StyledTableCell align="left" className="text-wrapper">
                  Details
                </StyledTableCell>
              </TableRow>
            </TableHead>
            {storeLocListitems.length !== 0 && (
              <TableBody>
                {storeLocListitems.map((storeLoc, index) => {
                  return (
                    <TableRow key={index + "_infra_store_row"}>
                      <StyledTableCell component="td" scope="row" className="text-wrapper">
                        <StyledStack direction={"row"}>{storeLoc.number_store_cyreen}</StyledStack>
                      </StyledTableCell>
                      {/* --------- name ---------- */}
                      <StyledTableCell component="td" scope="row" className="text-wrapper wrapLargeText">
                        <StyledStack direction={"row"}>{storeLoc.name}</StyledStack>
                      </StyledTableCell>
                      {/* ----------- owner --------- */}
                      <StyledTableCell component="td" scope="row" className="text-wrapper wrapLargeText">
                        <StyledStack direction={"row"}>{storeLoc?.company?.name}</StyledStack>
                      </StyledTableCell>
                      {/* ---------- Devices --------- */}
                      <StyledTableCell component="td" scope="row" className="text-wrapper">
                        <StyledStack direction={"row"} spacing={2}>
                          {/* ------- screens ---------- */}
                          {storeLoc.active_screens_count === 0 ? (
                            <HtmlTooltipDisabled title={"Screens"}>
                              <IconContainer>
                                <OndemandVideo sx={{ color: "#E6E6E6" }} />
                                <IconTextDisabled variant="body2"> {storeLoc.active_screens_count} </IconTextDisabled>
                              </IconContainer>
                            </HtmlTooltipDisabled>
                          ) : (
                            <HtmlTooltip title={"Screens"}>
                              <IconContainer>
                                <OndemandVideo sx={{ color: "#AF3241" }} />
                                <IconText variant="body2"> {storeLoc.active_screens_count} </IconText>
                              </IconContainer>
                            </HtmlTooltip>
                          )}

                          {/* ---------- area readers --------- */}
                          {storeLoc.area_readers_count === 0 ? (
                            <HtmlTooltipDisabled title={"Area readers"}>
                              <IconContainer>
                                <Router sx={{ color: "#E6E6E6" }} />
                                <IconTextDisabled variant="body2"> {storeLoc.area_readers_count} </IconTextDisabled>
                              </IconContainer>
                            </HtmlTooltipDisabled>
                          ) : (
                            <HtmlTooltip title={"Area readers"}>
                              <IconContainer>
                                <Router sx={{ color: "#AF3241" }} />
                                <IconText variant="body2"> {storeLoc.area_readers_count} </IconText>
                              </IconContainer>
                            </HtmlTooltip>
                          )}

                          {/* -------- checkIn readers ----------- */}
                          {storeLoc.checkin_readers_count === 0 ? (
                            <HtmlTooltipDisabled title={"Checkin readers"}>
                              <IconContainer>
                                <Input sx={{ color: "#E6E6E6" }} />
                                <IconTextDisabled variant="body2"> {storeLoc.checkin_readers_count} </IconTextDisabled>
                              </IconContainer>
                            </HtmlTooltipDisabled>
                          ) : (
                            <HtmlTooltip title={"Checkin readers"}>
                              <IconContainer>
                                <Input sx={{ color: "#AF3241" }} />
                                <IconText variant="body2"> {storeLoc.checkin_readers_count} </IconText>
                              </IconContainer>
                            </HtmlTooltip>
                          )}
                          {/* -------- checkout readers ----------- */}
                          {storeLoc.checkout_readers_count === 0 ? (
                            <HtmlTooltipDisabled title={"Checkout readers"}>
                              <IconContainer>
                                <Output sx={{ color: "#E6E6E6" }} />
                                <IconTextDisabled variant="body2"> {storeLoc.checkout_readers_count} </IconTextDisabled>
                              </IconContainer>
                            </HtmlTooltipDisabled>
                          ) : (
                            <HtmlTooltip title={"Checkout readers"}>
                              <IconContainer>
                                <Output sx={{ color: "#AF3241" }} />
                                <IconText variant="body2"> {storeLoc.checkout_readers_count} </IconText>
                              </IconContainer>
                            </HtmlTooltip>
                          )}
                        </StyledStack>
                      </StyledTableCell>

                      {/* -------- Store Status --------- */}
                      <StyledTableCell component="td" scope="row" className="text-wrapper">
                        <StyledStack direction={"row"} spacing={2}>
                          {/* <StyledSwitch checked={storeLoc.active} /> */}
                          {storeLoc.active === true ? (
                            <HtmlTooltip title={"Active"}>
                              <IconContainer>
                                <CheckCircle sx={{ color: "#AF3241", marginTop: "5px" }} />
                              </IconContainer>
                            </HtmlTooltip>
                          ) : (
                            <HtmlTooltipDisabled title={"Inactive"}>
                              <IconContainer>
                                <Cancel sx={{ color: "#E6E6E6", marginTop: "5px" }} />
                              </IconContainer>
                            </HtmlTooltipDisabled>
                          )}
                        </StyledStack>
                      </StyledTableCell>
                      {/* ----------- Store details ---------- */}
                      <StyledTableCell component="td" scope="row" className="text-wrapper">
                        <StyledStack direction={"row"} spacing={2}>
                          {/* For store of type “independent store”, the details button is greyed out (inactive) = branch and cyreen user type */}
                          {storeLoc?.company?.level === 4 &&
                          ["branch"].includes(loggedUserDetails.user_detail.user_type) ? (
                            <HtmlTooltipDisabled title={"Not Available"}>
                              <IconContainer>
                                <a
                                  href="javascript:void(0)"
                                  onClick={(e) => {
                                    e.preventDefault();
                                  }}
                                >
                                  <Settings sx={{ color: "#E6E6E6", marginTop: "5px" }} />
                                </a>
                              </IconContainer>
                            </HtmlTooltipDisabled>
                          ) : (
                            <HtmlTooltip title="Edit">
                              <IconContainer>
                                <a
                                  href="javascript:void(0)"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    navigate("/infrastructure/configure-location/" + storeLoc.id);
                                  }}
                                >
                                  <Settings sx={{ color: "#AF3241", marginTop: "5px" }} />
                                </a>
                              </IconContainer>
                            </HtmlTooltip>
                          )}
                        </StyledStack>
                      </StyledTableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        {/* --------- record count info --------- */}
        <Box mt={2} id="infoText">
          <StyledDarkRedInformationTypography variant="body2">
            Showing {storeLocListitems.length} out of {totalInfraStoreItems} results.
          </StyledDarkRedInformationTypography>
        </Box>
        {/* ---------- Load more button ----------- */}
        <Box height={20} />
        {storeLocListitems.length < totalInfraStoreItems && <LoadMoreButton clickAction={loadMore} />}
      </StyledMainBox>

      {/* ============ modal popup for different actions ========== */}
      {isModalOpen && (
        <NewLocation open={isModalOpen} size={"md"} usedFor="Add" sectionToEdit="Both" closeModal={closeModal} />
      )}
    </>
  );
};

export default AccessControl(Locations, "infrastructure");
