import React from "react";
import { StyledMainBox, StyledRightAlignedStack } from "../../components/commonComponents/StyledComponents/styled";
import { useNavigate, useParams } from "react-router-dom";
import AccessControl from "../../components/commonComponents/Hoc/AccessControl";
import { fetchCampaign } from "../../api_calls/campaignRequests";
import ActionButton, {
  ConditionalOutlinedButton,
  LightGreyButton,
} from "../../components/commonComponents/Buttons/ActionButton";
import { useDispatch, useSelector } from "react-redux";
import AnalyticsView from "./ModalContent/AnalyticsView";
import { updateStateData } from "../../Store/actions";
import BrandReportFilter from "./ModalContent/BrandReportFilter";

const BrandReportView = () => {
  const dispatchAction = useDispatch();
  const campaignDetails = useSelector((state) => state.userSelections);
  const { id } = useParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    fetchCampaign(id, "all");
    dispatchAction(
      updateStateData({
        brand_report_view: true,
      })
    );
  }, []);

  return (
    <StyledMainBox component="main">
      {typeof campaignDetails.campaign_detail !== "undefined" && typeof campaignDetails.brand_filters !== "undefined" && (
        <>
          <AnalyticsView viewMode={"Brand"} />
        </>
      )}
    </StyledMainBox>
  );
};

export default AccessControl(BrandReportView, "campaigns");
