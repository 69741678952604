import React from "react";
import { Box, Stack, Tab } from "@mui/material";
import PropTypes from "prop-types";
import SideNav from "../../components/commonComponents/Sidebar/SideNav";
import { Add, Download, Save, Upload } from "@mui/icons-material";
import ActionButton from "../../components/commonComponents/Buttons/ActionButton";
import { StyledMainBox, StyledTabs } from "../../components/commonComponents/StyledComponents/styled";
import { saveRoleInformation } from "../../api_calls/userManagement";
import UploadData from "./TabData/UploadData";
import AccessControl from "../../components/commonComponents/Hoc/AccessControl";
import DownloadData from "./TabData/DownloadData";

// to load tab content
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const DataExchange = () => {
  const [value, setValue] = React.useState(0);
  const tabsConfig = [
    {
      label: "Upload",
      icon: "",
      unique_key: "uploadDataExch",
      dashboard: <UploadData />,
      filter_component: <></>,
    },
    {
      label: "Download",
      icon: "",
      unique_key: "downloadDataExch",
      dashboard: <DownloadData />,
      filter_component: <></>,
    },
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // React.useEffect(() => {
  //   loadStores();
  // }, []);
  return (
    <StyledMainBox component="main">
      <h1>Data Exchange</h1>
      <Box
        sx={{
          display: "flex", // Make the Box a flex container
          // borderBottom: 1,
          // borderColor: "divider",
          position: "sticky",
          top: "0",
          zIndex: 1,
          backgroundColor: "#fff",
        }}
      >
        <Box component={"div"}>
          <StyledTabs value={value} onChange={handleChange} aria-label="data-exchange" textColor={"#F04B55"}>
            {tabsConfig.map((tabDetail) => {
              return <Tab key={tabDetail.unique_key} icon={tabDetail.icon} label={tabDetail.label} {...a11yProps(0)} />;
            })}
          </StyledTabs>
        </Box>
      </Box>
      {/* ============== tab contents =========== */}
      {tabsConfig.map((tabDetail, index) => {
        return (
          <CustomTabPanel value={value} index={index}>
            {tabDetail.dashboard}
          </CustomTabPanel>
        );
      })}
      {/* ============== users listing ============ */}
      <Box height={30} />
    </StyledMainBox>
  );
};

export default AccessControl(DataExchange, "infrastructure");
